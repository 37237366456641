<template>
  <div>
    <table-custom
      ref="dataTable"
      mode="client"
      :name="`${$customTable.getCustomTableName(module || $route.meta.module)}`"
      :loading="dataTable.isLoading"
      :data="dataTable.dataSet"
      :options="dataTable.options"
      @row-select="onRowSelect"
      @view-item="viewReport"
      @edit-item="editReport"
      @delete-item="deleteReport"
    >
      <div slot="afterFilter">
        <b-row v-if="showAddButton">
          <b-col>
            <b-button-group>
              <b-button variant="outline-dark" @click="addReport()">
                <font-awesome-icon icon="plus" /> Create
              </b-button>
              <b-button
                v-if="!dataTable.isLoading && filter.includes('my', 'user')"
                variant="outline-dark"
                @click="toggleView()"
              >
                <font-awesome-icon icon="list" /> {{ currentView.title }}
              </b-button>
              <b-button
                v-if="!dataTable.isLoading"
                variant="outline-dark"
                title="Review reports"
                :disabled="hasSelectedRows ? false : true"
                @click="reviewReports()"
              >
                <font-awesome-icon icon="signature" /> Review
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
      </div>

      <div slot="custom-actions" slot-scope="props">
        <div class="btn-group">
          <span
            class="btn btn-primary btn-sm"
            @click="viewReport(props.row.ID)"
          >
            <font-awesome-icon icon="eye"
          /></span>
          <span
            v-if="props.row.Reviewed == 'No'"
            class="btn btn-success btn-sm"
            @click="editReport(props.row.ID)"
          >
            <font-awesome-icon icon="pencil-alt"
          /></span>
          <span
            v-if="props.row.Reviewed == 'No'"
            class="btn btn-danger btn-sm"
            @click="deleteReport(props.row.ID)"
          >
            <font-awesome-icon icon="trash"
          /></span>
          <button
            class="btn btn-dark btn-sm"
            @click="viewContent(props.row.ID)"
            title="Preview"
          >
            <font-awesome-icon icon="search" />
          </button>

          <button
            :class="[
              props.row['FilesCount'] !== '0'
                ? 'btn btn-secondary btn-sm'
                : 'btn btn-outline-secondary btn-sm'
            ]"
            @click="toggleFiles(props.row['ID'])"
          >
            <font-awesome-icon icon="download" />
          </button>
        </div>
      </div>
      <span slot-scope="props" slot="child_row">
        <files-container
          ref="files"
          :module-id="$route.meta.module.id"
          :entity-id="props.row['ID']"
        />
      </span>
    </table-custom>

    <b-modal
      ref="preview-report-modal"
      centered
      hide-footer
      size="lg"
      :title="previewModal.title"
    >
      <b-card>
        <b-card-body>
          <div class="preview-report" v-html="previewModal.htmlContent" />
        </b-card-body>
      </b-card>
      <hr />

      <div class="form-row d-flex  justify-content-end">
        <b-button
          v-if="previewModal.isReviewer && !previewModal.Reviewed"
          variant="warning"
          class="m-1"
          @click="setReportReviewed()"
        >
          <font-awesome-icon icon="signature" /> Mark as Reviewed
        </b-button>
        <b-button variant="outline-dark" class="m-1" @click="closeModal()">
          <font-awesome-icon icon="times" /> Close
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import FilesContainer from '@/components/FilesContainer'
import TableCustom from '@/components/TableCustom'
import { mapState } from 'vuex'

export default {
  props: {
    filter: {
      type: String,
      default: ''
    },
    userId: {
      type: [String, Number],
      default: undefined
    },
    showAddButton: {
      type: Boolean,
      default: true
    },
    module: {
      type: Object,
      default: undefined
    }
  },
  components: {
    FilesContainer,
    TableCustom
  },

  data: function () {
    return {
      rawData: {},
      selectedRows: [],
      appliedFilters: [],
      dataTable: {
        visible: true,
        isLoading: false,
        options: {
          columns: [
            'ID',
            'Report Name',
            'Report Date',
            'From',
            'To',
            'Frequency',
            'Submitter',
            'Responsible',
            'Reviewer',
            'Reviewed',
            'Status',
            'Actions'
          ],
          filterable: [
            'ID',
            'Report Name',
            'Report Date',
            'From',
            'To',
            'Frequency',
            'Submitter',
            'Responsible',
            'Reviewer',
            'Reviewed',
            'Status',
            'Actions'
          ],
          uniqueKey: 'ID',
          showChildRowToggler: false,
          filterByColumn: true,
          perPage: 50,
          perPageValues: [],
          showCustomActions: true,
          showChildRows: true,
          selectableRows: true,
          hideSettingsBar: false
        },

        dataSet: [],
        onRowClick: function () {},
        totalRecords: 0
      },
      currentView: {
        title: 'My Reports',
        mode: 2
      },
      previewModal: {
        ID: '',
        title: '',
        htmlContent: '',
        isReviewer: false,
        Reviewed: false
      }
    }
  },
  computed: {
    hasSelectedRows () {
      return this.selectedRows.length > 0
    },
    ...mapState({
      profile: state => state.profile
    })
  },
  mounted () {
    console.log('ReportsTable mounted', this.filter, this.userId)

    if (this.filter == 'user' && this.userId) this.getData()

    /*
    if (this.filter === "my") {
      this.dataTable.columns = this.dataTable.columns.filter(
        c => !["Submitter", "Responsible", "Reviewer"].includes(c)
      );
    }
    */
  },
  methods: {
    onRowSelect (e) {
      this.selectedRows = this.getSelectedRows()
      this.$emit('row-select', e)
    },
    getSelectedRows () {
      let selected = JSON.parse(
        JSON.stringify(this.$refs['dataTable'].selectedRows)
      )

      return selected
    },
    async getData (payload) {
      let self = this

      this.dataTable.isLoading = true

      //this.dataTable.dataSet = [];

      let url = 'users/reports'
      let method = 'post'

      if (this.filter === 'review') url = 'users/reports/review'
      if (this.filter === 'my') url = 'users/reports/my'
      if (this.filter === 'user') {
        url = `users/${this.userId}/reports`
        method = 'get'
      }

      console.log('reportstable.getData', url, payload)
      return this.$api[method](url, payload)
        .then(response => {
          self.dataTable.isLoading = false

          this.rawData = response

          this.dataTable.dataSet = this.rawData

          //this.filterData(this.filteringPanel.selected);

          this.$emit('loaded', this.dataTable.dataSet.length)

          this.$emit('toggle-view')
        })
        .catch(error => {
          console.log(error)
          self.dataTable.isLoading = false
          self.$form.msgBoxOk('Error occured')
        })
    },
    toggleFiles (id) {
      this.$refs.dataTable.toggleChildRow(id)
    },
    addReport: function () {
      this.$router.push({
        name: 'Report submission',
        params: {
          action: 'create'
        }
      })
    },
    viewReport: function (id) {
      this.$router.push({
        name: 'Report submission',
        params: {
          action: 'view',
          id: id
        }
      })
    },
    editReport: function (id) {
      this.$router.push({
        name: 'Report submission',
        params: {
          action: 'edit',
          id: id
        }
      })
    },
    deleteReport: async function (id) {
      let report = this.dataTable.dataSet.find(item => item.ID === id)

      let confirm = await this.$form.showConfirmation(
        `${report['Report Name']} #${report.ID} will be deleted. Do you want to proceed?`
      )

      if (!confirm) return

      let self = this

      this.$api
        .delete(`users/reports/${report.ID}`)
        .then(() => {
          self.$form.makeToastInfo('Report deleted')

          //self.drawReportsTable();
          self.getData()
        })
        .catch(response => {
          console.log(response)

          self.$form.makeToastError(response.data.message)
        })
    },
    setReportReviewed () {
      let self = this

      this.$api
        .put(`users/reports/${this.previewModal.ID}/reviewed`)
        .then(response => {
          self.previewModal.Reviewed = true

          self.getData()
          //self.drawReportsTable();

          self.$form.msgBoxOk(response.message)
        })
        .catch(error => {
          console.log(error)

          self.$form.msgBoxOk(error.message)
        })
    },
    closeModal: function () {
      this.$refs['preview-report-modal'].hide()
    },
    viewContent (id) {
      let report = this.dataTable.dataSet.find(item => item.ID === id)

      if (!report) return

      this.previewModal.ID = report['ID']
      this.previewModal.title = report['Report Name']
      this.previewModal.htmlContent = report['Content']
      this.previewModal.Reviewed = report['Reviewed'] == 'Yes'

      let rev = report['reviewers_id'] ? report['reviewers_id'].split(',') : []

      this.previewModal.isReviewer = rev.includes(
        this.profile.data.id.toString()
      )

      this.$refs['preview-report-modal'].show()
    },
    filterReviewers (reports) {
      let result = []

      reports.forEach(report => {
        let rev = report['reviewers_id']
          ? report['reviewers_id'].split(',')
          : []

        if (
          report['Reviewed'] === 'No' &&
          rev.includes(this.profile.data.id.toString())
        ) {
          result.push(report)
        }
      })

      return result
    },
    async reviewReports () {
      let reports4Review = this.filterReviewers(this.selectedRows)

      if (!reports4Review.length) {
        await this.$form.msgBoxOk(`There are no available reports for review`)

        return
      }
      const reportsList = reports4Review
        .map(a => a['Report Name'] + ' [' + a['Report Date'] + ']')
        .join()

      let confirm = await this.$form.showConfirmation(
        `Following reports will be reviewed: <p>${reportsList}.</p>Do you want to proceed?`
      )

      if (!confirm) return

      let self = this

      this.dataTable.isLoading = true

      let payload = this.selectedRows.map(a => a['ID'])

      this.$api
        .put(`users/reports/bulk/review`, payload)
        .then(response => {
          self.$form.makeToastInfo(response.message)

          self.getData(self.appliedFilters)

          this.selectedRows = []
        })
        .catch(response => {
          console.log(response)

          self.getData(self.appliedFilters)

          self.$form.makeToastError(response.message)
        })
    },

    toggleView () {
      if (this.currentView.mode === 2) {
        this.currentView.mode = 1
        this.currentView.title = 'My Submits'
      } else if (this.currentView.mode === 1) {
        this.currentView.mode = 2
        this.currentView.title = 'My Reports'
      }

      this.$emit('toggle-view')
    }
  }
}
</script>

<style></style>
